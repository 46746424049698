<template>
  <div class="col-sm-12 col-md-12 col-lg-3 fd-side-menu-bg pc">
    <div class="fd-side-menu">
      <ul
        id="accordionSideMenu"
        class="fd-side-menu-nav-list"
      >
        <li
          v-if="$store.state.fastPersonalizacao.menu_icone_meus_cursos"
          data-toggle="collapse"
          href="#collapseUsuarios"
          role="button"
          aria-expanded="false"
          aria-controls="collapseUsuarios"
          class="fd-side-menu-nav-list-item"
        >
          <a
            v-if="!$store.state.fastPersonalizacao.meus_cursos_nome"
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos')"
          >Meus Cursos</a>
          <a
            v-else
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos')"
          >{{ $store.state.fastPersonalizacao.meus_cursos_nome }}</a>
        </li>
        <li
          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
          class="fd-side-menu-nav-list-item"
        >
          <a                      
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turmas')"
          >Minhas turmas</a>         
        </li>
        <li class="fd-side-menu-nav-list-item d-none fast-menu-lives-aluno-iuea">
          <a
            v-if="!$store.state.fastPersonalizacao.minhas_lives_nome" 
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/lives'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/lives')"
          >Ao vivo</a>
          <a
            v-else
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/lives'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/lives')"
          >{{ $store.state.fastPersonalizacao.minhas_lives_nome }}</a>
        </li>
        <li
          v-if="$store.state.fastPersonalizacao.aluno_visualiza_minhas_lives"
          class="fd-side-menu-nav-list-item fast-menu-lives-aluno-fast"
        >
          <a
            v-if="!$store.state.fastPersonalizacao.minhas_lives_nome"
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/lives'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/lives')"
          >Minhas lives</a>
          <a
            v-else
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/lives'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/lives')"
          >{{ $store.state.fastPersonalizacao.minhas_lives_nome }}</a>
        </li>
        <li class="fd-side-menu-nav-list-item">
          <a
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/atendimento'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/atendimento')"
          >Atendimento</a>
        </li>

        <li
          v-if="$store.state.fastPersonalizacao.aluno_visualiza_mural"
          class="fd-side-menu-nav-list-item"
        >
          <a
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/mural'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/mural')"
          >Mural</a>
        </li>
        <li
          v-if="$store.state.fastPersonalizacao.aluno_visualiza_meus_cadernos"
          class="fd-side-menu-nav-list-item"
        >
          <a
            v-if="!$store.state.fastPersonalizacao.meus_cadernos_nome"
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cadernos'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cadernos')"
          >Meus cadernos</a>
          <a
            v-else
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cadernos'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cadernos')"
          >{{ $store.state.fastPersonalizacao.meus_cadernos_nome }}</a>
        </li>
        <li
          v-if="$store.state.fastPersonalizacao.aluno_visualiza_minhas_capacitacoes"
          class="fd-side-menu-nav-list-item fast-plataforma-iuea-hide"
        >
          <a
            v-if="!$store.state.fastPersonalizacao.minhas_capacitacoes_nome"
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/minhas-capacitacoes'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/minhas-capacitacoes')"
          >Minhas capacitações</a>
          <a
            v-else
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/minhas-capacitacoes'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/minhas-capacitacoes')"
          >{{ $store.state.fastPersonalizacao.minhas_capacitacoes_nome }}</a>
        </li>
        <!-- <li
          v-if="$store.state.fastPersonalizacao.aluno_visualiza_loja_plataforma"
          class="fd-side-menu-nav-list-item fast-plataforma-iuea-hide"
        >
          <a
            v-if="!$store.state.fastPersonalizacao.loja_plataforma_nome"
            :href="'/loja-virtual/' + $route.params.id_plataforma"
            @click.prevent="$router.push('/loja-virtual/' + $route.params.id_plataforma)"
          >Loja da plataforma</a>
          <a
            v-else
            :href="'/loja-virtual/' + $route.params.id_plataforma"
            @click.prevent="$router.push('/loja-virtual/' + $route.params.id_plataforma)"
          >{{ $store.state.fastPersonalizacao.loja_plataforma_nome }}</a>
        </li> -->
        <li
          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
          class="fd-side-menu-nav-list-item"
        >
          <a                      
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-requerimentos'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-requerimentos')"
          >Meus requerimentos</a>         
        </li>
        <li
          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
          class="fd-side-menu-nav-list-item"
        >
          <a                      
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/minhas-ocorrencias'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/minhas-ocorrencias')"
          >Minhas ocorrências</a>         
        </li>
        <li
          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
          class="fd-side-menu-nav-list-item"
        >
          <a                      
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/perguntas'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/perguntas')"
          >Perguntas</a>         
        </li>
        <li
          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
          class="fd-side-menu-nav-list-item"
        >
          <a                      
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/aulas-particulares'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/aulas-particulares')"
          >Aula Particular</a>         
        </li>
        <li
          v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
          class="fd-side-menu-nav-list-item"
        >
          <a                      
            :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/revisao-redacao'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/revisao-redacao')"
          >Revisão de redação</a>         
        </li>
      </ul>
    </div>
    <div class="fd-side-menu-border" />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
